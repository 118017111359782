.contact-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.at-container {
    
    height: 100%;
}
.ContactUsFlipCardsText{
    display: flex !important;
    margin-top: 7.5rem;
    text-align: center;
    justify-content: center;
    font-size: 2.5rem;
    font-weight: 800;
}
.highlight {
    background: linear-gradient(to right, #04316d, #018099);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent; /* Makes the text transparent, showing the background */
    display: inline; /* Ensures the gradient spans only the text */
  }
  
@media only screen and (min-width: 768px ) and (max-width: 1024px){
    .contact-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-top: 2rem;
        gap: 38px;
        margin-bottom: 2rem;
    }
    .ContactUsFlipCardsText {
        
        font-size: 1.9rem;
        
    }
}
@media only screen and (min-width:368px) and (max-width:767px)  {
    .ContactUsFlipCardsText{
        display: flex !important;
        margin-top: 5.5rem;
        text-align: center;
        justify-content: center;
        font-size: 2.5rem;
        font-weight: 800;
    }
    .contact-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-top: 2rem;
        gap: 15px;
        margin-bottom: 2rem;
    }
    .ContactUsFlipCardsText {
        
        font-size: 1.5rem;
        
    }
}
@media only screen and (max-width:367px)
{
    .ContactUsFlipCardsText{
        display: flex !important;
        margin-top: 5.5rem;
        text-align: center;
        justify-content: center;
        font-size: 2.5rem;
        font-weight: 800;
    }
    .contact-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-top: 2rem;
        gap: 15px;
        margin-bottom: 2rem;
    }
    .ContactUsFlipCardsText {
        
        font-size: 1.5rem;
        
    }
}