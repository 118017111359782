.Feature-carousel {
  height: 30rem;
  width: 100%;
}

.feature-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust the min width as needed */
  grid-gap: 50px; /* Adjust the gap between cards */
}

.bear-react-carousel__pagination-group {
  bottom: -36px !important;
}

.bear-react-carousel__slide-item {
  display: flex;
  justify-content: center;
  height: 100% !important;
}

.bear-react-carousel__content {
  top: 5%;
}

.custom-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(32, 6, 94); /* Border for visualization */
  border-radius: 8px;
  margin: 3px;
  width: 90%;
  overflow: hidden;
  transition: transform 0.3s ease;
  height: 350px; /* Set a fixed height for each card */
}

.card-content {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  padding: 20px;
  gap: 10px 0;
}

.card-content h3 {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}

.card-content p {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  color: #000000;
}

.feature-button {
  background-color: #0010f3;
  color: white;
  transition-duration: .2s;
  border: none;
  cursor: pointer;
  font-weight: 600;
  padding: 8px 0;
  width: 50%;
}

.feature-button:hover {
  background-color: #0056b3;
}

.features-content {
  text-align: center;
  font-size: 0.9em;
  font-weight: 600;
  color: rgb(99, 99, 99);
}

.feature-readmore {
  color: #ffffff;
  background-color: #04316D;
  transition-duration: .2s;
  padding: 8px 0;
  width: 105px;
  border: none;
  cursor: pointer;
  font-weight: 600;
}

.feature-readmore:hover {
  font-weight: 700;
  transition-duration: .2s ease-in-out;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .custom-card {
    
    height: 250px;
}
.bear-react-carousel__slide-item {
  display: flex;
  justify-content: center;
  height: 100% !important; /* Ensure the slide item takes full height */
}
.bear-react-carousel__pagination-group {
    bottom: -15px !important;
}
.Feature-carousel {
  height: 18rem;
  width: 100%;
 
}
.cuttext
{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
  .card-content h3 {
    font-size: 1.1rem;
  }
  .card-content p {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 368px) and (max-width: 767px) {
 
  .custom-card {
    
    height: auto;
}
.feature-button, .feature-readmore {
  padding: 6px 0;
}
.bear-react-carousel__pagination-group {
    bottom: -39px !important;
}
.Feature-carousel {
  height: 12rem;
  width: 100%;
}
  .card-content h3 {
    font-size: 0.8rem;
  }
  .card-content p {
    font-size: 0.7rem;
  }
  
  .features-content {
    font-size: 0.8em;
  }
  .cuttext
  {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    
  }
}

@media only screen and (max-width:367px)  {
  .custom-card {
    
    height: auto;
}
.bear-react-carousel__pagination-group {
    bottom: -39px !important;
}
.Feature-carousel {
  height: 12rem;
  width: 100%;
}
  .card-content h3 {
    font-size: 0.8rem;
  }
  .card-content p {
    font-size: 0.7rem;
  }
  
  .features-content {
    font-size: 0.8em;
  }
  .cuttext
  {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}