[id] {
    scroll-margin-top: 100px; /* Example value */
  }

.services-background {
    width: 100%;
    background-color: #04316d;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* background-image: linear-gradient(to right, #04316d, #018099); */
    height:600px;
    clip-path: polygon(0 0, 100% 0%, 100% 89%, 0 99%);

    /* --mask:
      radial-gradient(7.05px at 50% calc(100% - 9.8px),#000 99%,#0000 101%) calc(50% - 8px) 0/16px 100%,
      radial-gradient(7.05px at 50% calc(100% + 5.8px),#0000 99%,#000 101%) 50% calc(100% - 4px)/16px 100% repeat-x;
    -webkit-mask: var(--mask);
            mask: var(--mask); */
  }
/* Market Place CArds */
.left-services-background{
    width: 50%;
    align-items: center;
}
.left-services-background h3{
    color: white;
    font-family: "jost", sans-serif;
    font-size: 3rem;
    margin-left: 10%;
    font-weight: 700;
}
.left-services-background p{
    color: white;
    font-family: "jost", sans-serif;
    margin-left: 10%;
    font-size: 1.3rem;
    font-weight: 300;
}

/* CSS */
.button-17 {
  align-items: center;
  appearance: none;
  margin-left: 10%;

  font-family: "Jost", sans-serif;
  background-color: #fff;
  border-radius: 30px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-size: 20px;
  font-weight: 500;
  height: 55px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}

.subservices
{
    text-align: center;
    margin-bottom: -30px;
}
.subservices h2{
    font-family: "jost", sans-serif;
    color:#04316d;
}
.button-17:hover {
  background: #F6F9FE;
  color: #174ea6;
}

.button-17:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.button-17:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.button-17:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.button-17:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.button-17:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}
.right-services-background
{
    display: flex;
    align-content: center;
    justify-content: center;
    
    width: 50%;
}
.right-services-background img
{
    display: flex;
    align-content: center;
    justify-content: center;
    height: 26em;
    border-radius: 15px;
    border: 5px solid white;
    margin-right: 7%;
    margin-top: 5%;
    width: 75%;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.Market-cards-heading
{
    font-size: 30px;
    font-weight: 800;
    font-family: "Jost", sans-serif;
    color: #04316d;
    text-align: center;
    line-height: 2.6rem;
}

/* Domain Cards */
.DoaminCardGridContainer{
    display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 10px;
}

@media only screen and (min-width: 768px ) and (max-width: 1024px) {
    
    .right-services-background img {
       
        margin-right: 7%;
        margin-top: 8%;
        width: 100%;
    }
    .left-services-background h3{
        font-size: 2.7rem;
        margin-left: 3%;
        font-weight: 650;
    }
    .left-services-background p{
        color: white;
        font-family: "jost", sans-serif;
        margin-left: 3%;
        font-size: 1.2rem;
        font-weight: 300;
    }
    
    .MarketPlaceCardGridContainer {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
    }

    .DoaminCardGridContainer{
        display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 5px;
    }
    
}


@media only screen and (min-width:368px) and (max-width:767px)  {
    .services-background {
        width: 100%;
        background-color: #04316d;
        display: flex;
        flex-direction: column;
        height: 700px;
    }
    .left-services-background {
        width: 100%;
        margin-top: 20%;
        align-items: center;
    }
    .left-services-background h3{
        text-align: center;
        font-size: 2rem;
        margin-left: 3%;
        font-weight: 650;
    }
    .left-services-background p{
        text-align: center;
        color: white;
        font-family: "jost", sans-serif;
        margin-left: 3%;
        font-size: 1rem;
        font-weight: 300;
    }
    .right-services-background
{
    /* margin-left: 5%; */
    width: 100%;
}
.right-services-background img
{
    display: flex;
    align-content: center;
    justify-content: center;
    height: 25rem;
    border-radius: 15px;
    margin-right: 0 !important;
    border: 5px solid white;
    /* margin-right: 7%;
    margin-top: 5%; */
    width: 90%;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.Service-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
    .MarketPlaceCardGridContainer {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
    }
    .DoaminCardGridContainer{
        display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 5px;
    }
}

@media only screen and (max-width:367px)  {
    .services-background {
        width: 100%;
        background-color: #04316d;
        display: flex;
        flex-direction: column;
        height: 700px;
    }
    .left-services-background {
        width: 100%;
        margin-top: 20%;
        align-items: center;
    }
    .left-services-background h3{
        text-align: center;
        font-size: 2rem;
        margin-left: 3%;
        font-weight: 650;
    }
    .left-services-background p{
        text-align: center;
        color: white;
        font-family: "jost", sans-serif;
        margin-left: 3%;
        font-size: 1rem;
        font-weight: 300;
    }
    .right-services-background
{
    /* margin-left: 5%; */
    width: 100%;
}
.right-services-background img
{
    display: flex;
    align-content: center;
    justify-content: center;
    height: 25rem;
    border-radius: 15px;
    margin-right: 0 !important;
    border: 5px solid white;
    /* margin-right: 7%;
    margin-top: 5%; */
    width: 90%;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.Service-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
    .MarketPlaceCardGridContainer {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
    }
    .DoaminCardGridContainer{
        display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 5px;
    }
}