.card-container {
    display: flex; /* Use Flexbox for layout */
    align-items: center; /* Align items vertically in the center */
    background-color:    #e6eeedd2;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px; /* Add some margin between cards */
  }
  
  .hero-image-container {
    flex: 0 0 auto; /* Don't allow the image container to grow or shrink */
    margin-right: 20px; /* Add some space between image and content */
  }
  
  .hero-image {
    width: 100px; /* Adjust image width as needed */
    height: 100px; /* Maintain aspect ratio */
    border-radius: 50%;
  }
  
  .main-content {
    flex-grow: 1; /* Allow the main content to grow and take remaining space */
  }
  
  .main-content h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px; /* Add some space below the header */
  }
  
  .main-content p {
    font-size: 14px;
    font-weight: 400;
    margin: 0; /* Remove default margin for paragraphs */
  }
  @media only screen and (min-width:368px) and (max-width:767px) 
{
  .card-container {
    margin-bottom: 2px;
  }


  .hero-image{
    width: 80px;
    height: 80px;
  }
  .main-content h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px; /* Add some space below the header */
  }
  
  .main-content p {
    font-size: 15px;
    font-weight: 300;
    margin: 0; /* Remove default margin for paragraphs */
  }
}
