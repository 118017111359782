.nav-class {
  background-color: rgb(249 245 245) ;
  background: transparent;
  transition: background-color 0.3s ease, padding 0.3s ease;
  color:white;
  /* padding: 15px 0; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;; */
}
  
  .navbar-toggler-icon.close-icon {
    font-size: 1.5rem;
    color: rgba(33, 37, 41, 0.5);
    background-image: none;
  }
  .scrolled {
    /* background-image: linear-gradient(to right, #04316d, #018099); Gradient background on scroll */
    background-color: rgb(248, 244, 244);
    }
  
  /* Navigation.css */

.initial-navtext {
  color: white !important;
  font-weight: 700;
}

.scrolled-navtext {
  color: #03234d !important;
  font-weight: 700;
}

.navbar.scrolled {
  background-color: #f1f1f1; /* Change to your desired background color when scrolled */
}

.navbar, .nav-link {
  transition: color 0.3s ease, background-color 0.3s ease;
}
  /* Styles for the header section above the navigation bar */
  .header-class {
    background-color: #04316d;
    /* White background for the top bar */
  }
  
  .mininavtext {
    color: white !important;
  }
  
  .navtext {
    color: #00204b !important;
    font-weight: 700;
  }
  .navBarButton{
    background-color: #04316d;
    color: white;
    font-family: "Jost", sans-serif;
    font-weight: 700;
  }
  /* Center the nav items in the navbar */
  .navbar-nav {
    display: flex;
    justify-content: center;
    flex: 1;
    /* Allow the nav items to take the remaining space */
    gap: 20px;
    /* Add spacing between nav items */
  }
  
  .navbar-nav .nav-item {
    display: inline-block;
    position: relative;
  }
  
  /* Align the logo to the left and social icons to the right */
  .navbar-brand {
    flex: 0;
  }
  
  .navbar .social-icons {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  
  /* Main dropdown menu styling */
  .navbar .dropdown-menu {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 1000;
    margin-top: 0;
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }
  
  /* Show main dropdown menus on hover */
  .navbar .dropdown:hover > .dropdown-menu {
    display: block;
  }
  
  /* Submenu styles */
  .dropdown-submenu {
    position: relative;
  }
  
  .dropdown-submenu .dropdown-menu {
    display: none;
    top: 0;
    left: 100%;
    margin-top: -1px;
    z-index: 2000;
  }
  
  /* Show submenus on hover for submenus specifically */
  .dropdown-submenu:hover > .dropdown-menu {
    display: block;
  }
  
  /* Main dropdown item focus and hover states */
  .dropdown-item:focus,
  .dropdown-item:hover {
    background-color: #fcfdff;
    color: #04387c;
  }
  
  .logo {
    height: 60px;
    width: 154px;
  }
  
  /* Ensuring proper display of dropdowns */
  .navbar .dropdown-menu.show {
    display: block;
  }
  
  /* Custom styling for main dropdown items with arrows */
  .navbar .nav-item.dropdown > .nav-link::after {
    /* content: ' ▾'; Add down arrow */
    margin-left: 5px;
    font-size: 0.8em;
    color: #04316d;
  }
  
  /* Remove arrows from submenu items */
  .dropdown-submenu > .dropdown-toggle::after {
    content: "";
    /* Remove arrow for submenu items */
  }
  
  /* Adjusting visibility of submenus within the parent dropdown */
  .dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
    z-index: 2000;
  }
  
  /* Hover effect for nav links */
  .custom-hover:hover {
    color: #2a006e;
    /* Change the color on hover */
    transform: scale(1.1);
    /* Slightly enlarge on hover */
    font-weight: 600;
    /* Increase font weight on hover */
    transition: color 0.3s ease, transform 0.3s ease;
    /* Smooth transition for color and transform */
  }
  
  .custom-hover:active {
    color: #120052 !important;
    /* Keep hover color on active state */
    transform: scale(1.1);
    /* Keep transform on active state */
    font-weight: 600;
    /* Keep font weight on active state */
  }
  
  /* Additional styling for the top bar text */
  .top-bar small {
    color: #04387c;
    /* Text color for the top bar */
  }
  
  .navbar-toggler {
    border-color: rgba(255, 255, 255, 0.1);
    /* Light border color */
  }
  
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%2833, 37, 41, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    /* Light icon color */
  }
  
  /* Mega Menu */
  .megaMenuContainer {
    position: unset !important;
  }
  
  .megaMenuContainer > ul {
    top: 4px;
    width: 100vw;
    /* display: flex !important; */
  }
  
  .megaMenuContent {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    row-gap: 2rem;
    max-height: 70vh;
    overflow-y: scroll;
  }
  .navbarbutton
  {
    background-color: #04387c;
    /* border-radius: 1%; */
    color: white;
    font-family: "jost", sans-serif;
    font-weight: 400;
    font-size: 18px;
  }
  .dropdown-submenu {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    height: -webkit-fill-available;
  }
  
  .dropdown-submenu > a {
    text-decoration: none;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .megaMenu-subMenu {
    text-decoration: none;
    list-style: none;
    margin: 0 !important;
    padding: 0 !important;
  }
  
  @media screen and (min-width: 769px) {
    .megaMenuContent {
      grid-template-columns: repeat(3, 1fr);
      row-gap: 1.5rem;
    }
  }
  
  @media screen and (min-width: 968px) {
    .navbar-nav {
      height: 70px;
      justify-content: center;
      align-items: center;
    }
  
    .nav-link {
      height: -webkit-fill-available;
      line-height: 55px;
    }
  }
  
  @media screen and (min-width: 1025px) {
    .megaMenuContent {
      grid-template-columns: repeat(4, 1fr);
      row-gap: 2rem;
    }
  }
  
  @media screen and (min-width: 1366px) {
    .megaMenuContent {
      grid-template-columns: repeat(5, 1fr);
    }
  }
  
  @media screen and (min-width: 1800px) {
    .megaMenuContent {
      grid-template-columns: repeat(6, 1fr);
    }
  }
  
  @media screen and (max-width: 768px) {
    .megaMenuContent {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 1.5rem;
      max-height: 50vh;
    }
  }
  
  @media screen and (max-width: 500px) {
    .megaMenuContent {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 1rem;
    }
  
    .megaMenuContent {
      font-size: 15px !important;
    }
  }
  
  /* @media screen and (max-width: 1280) {
      .megaMenuContent {
          width: 100%;
          grid-template-columns: repeat(2, 1fr);
          row-gap: 1rem;
      }
  
      .megaMenuContent {
          font-size: 12px !important;
      }
  } */