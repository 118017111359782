/*** Footer ***/
.footer
{
    position: relative;
    bottom: 0;
    left: 0;
}
.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: var(--light);
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}
.footer-class{
    background-color: #04316d ;
    height: auto !important;
}
.footer a{
    text-decoration: none;
}
.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    color: var(--primary);
    letter-spacing: 1px;
    box-shadow: none;
}
.btn-class{
    background-color: #04316d;
    border: #04316d;
}

.copyright {
    color: var(--light);
    background: #04316d ;
    color: white;
    
}

.copyright a {
    color: var(--light);
}

.copyright a:hover {
    color: var(--primary);
}
