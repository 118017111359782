@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;


body{
  background-color: white;
}

/* styles.css */
.content {
  margin-top:5rem;
  padding:0 3.4% 0 3.4%;
  height: auto;
}
.main-body-content{
  margin-top:7rem;
  padding:0 3.4% 0 3.4%;
  height: auto;
}
@media only screen and (min-width: 768px ) and (max-width: 1024px) {
  .content{
    margin-top:2rem;
  }

}
@media only screen and (min-width:368px) and (max-width:767px)  {
  .content{
    margin-top:2rem;
  }
}
@media only screen and (max-width:367px)  {
  .content{
    margin-top:2rem;
  }
}