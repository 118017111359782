/* Styles for the carousel container */
.carousel-container {
  position: relative;
  width: 100%;
  margin: 50px auto;
  overflow: hidden;
}

/* Styles for the content section which holds the cards */
.carousel-content {
  display: flex;
  transition: transform 0.9s ease-in-out;
  will-change: transform;
}

/* Styles for individual cards */
.carousel-card {
  flex: 0 0 20%; /* 20% of the container's width to show 5 cards */
  margin: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.carousel-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.carousel-card p {
  font-size: 18px;
  color: white;
  font-weight: 600;
  font-family: "Jost", sans-serif;
}

/* Image inside the card */
.carousel-card img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 10px;
}

/* Styles for the navigation arrows */
.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.carousel-arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

.left-arrow {
  left: 0;
  top: 127px;
  background-color: #04316d;
  box-shadow: rgb(38, 57, 77) 4px 6px 30px 1px;
}

.right-arrow {
  right: 0px;
  top: 127px;
  background-color: #04316d;
  box-shadow: rgb(38, 57, 77) 4px 6px 30px 1px;
}

/* Styles for the pagination dots */
.carousel-pagination {
  text-align: center;
  margin-top: 20px;
}

.pagination-dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.pagination-dot.active {
  background-color: #717171;
}

.card-link {
  display: block; 
  text-decoration: none; 
  color: inherit; 
  width: 100%;
  height: 100%;
}
